/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://qcxjhw3vn5e37brlof3h37zslu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kxzn43hz6fcsfbetxsgbwlc3zi",
    "aws_cognito_identity_pool_id": "us-east-1:6c7f6a78-93f2-408c-960a-a29686cb8e4e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qfO8iFaMq",
    "aws_user_pools_web_client_id": "3bhanov7m2qrmme5ajnj3eatuk",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "amplifyreactgraphql02a0c10a056249e09ea985ade00743110-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
